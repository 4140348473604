// Gallery.js

import React, { useState, useEffect } from 'react';
import './Gallery.css';
import axiosInstance from '../../../axiosInstance';
import BaseUrl from '../../../BaseUrl';

function Gallery() {

  const [galleryList, setGalleryList] = useState([]);

  useEffect(() => {
    // Fetch gallery records from the API
    axiosInstance
      .get('/api/get-all-gallery')
      .then((response) => {
        setGalleryList(response.data.gallery_items);
      })
      .catch((error) => {
        console.error('Error fetching gallery records:', error);
      });
  }, []);

  const itemsPerPage = 12;
  const [currentPage, setCurrentPage] = useState(1);

  const handleNextPage = () => {
    setCurrentPage(prevPage => prevPage + 1);
    window.scrollTo(0, 0);
  };

  const handlePrevPage = () => {
    setCurrentPage(prevPage => (prevPage > 1 ? prevPage - 1 : prevPage));
    window.scrollTo(0, 0);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedImages = galleryList.slice(startIndex, endIndex);


  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []); 

  return (
    <div className='GalContainer'>
        <h2>Newberry in action</h2>
      <div className="white-space-preline gallery">
        {paginatedImages.map((item) => (
          <div key={item.id} className="white-space-preline image">
            <img src={`${BaseUrl}/images/${item.image}`} alt={`mg ${item.id}`} />

            <h4 style={{color: '#f5ffff'}}>{item.title}</h4>
          </div>
        ))}
      </div>
      <div className="white-space-preline pagination">
        <button onClick={handlePrevPage} disabled={currentPage === 1}>
          Previous
        </button>
        <span>Page {currentPage}</span>
        <button
          onClick={handleNextPage}
          disabled={endIndex >= galleryList.length}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default Gallery;
