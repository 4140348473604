import React, { useState, useEffect } from 'react';
import './SliderCarousel.css';

import Image1 from '../images/ameenah/usda-fs.jpg';


// import { ArrowRight } from '@mui/icons-material';


const images = [
  {
    'id': 1,
    'image': Image1,
    'description': `At the Forest Service One-Stop Service Center, we extend our dedication to cultivating the future of agriculture by empowering individuals to explore a range of dynamic career paths.`
  },
];

const SliderCarousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isPaused, setIsPaused] = useState(false);



  // const goTo = () => {
  //   if (isPaused) {
  //     setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
  //   }
  // };

  useEffect(() => {
    const nextSlide = () => {
      if (!isPaused) {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
      }
    };
    
    const autoSlideInterval = setInterval(() => {
      nextSlide();
    }, 30000); // Change to 20000 for 20 seconds as per your original code

    return () => {
      clearInterval(autoSlideInterval);
    };
  }, [isPaused]);

  const handleMouseEnter = () => {
    setIsPaused(true);
  };

  const handleMouseLeave = () => {
    setIsPaused(false);
  };

  return (
    <div className="white-space-preline banner-sliders-container" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div className="white-space-preline sliders">
        <div
          className="white-space-preline sliders-wrapper"
          style={{
            transform: `translateX(-${currentSlide * 100}%)`,
            transition: 'transform 0.5s ease-in-out',
          }}
        >
          {images.map((object, index) => (
            <div key={index} className="white-space-preline slidem">
              <img src={object.image} alt={`Slidem ${index}`} />
              <div className='descriptionHolder'>
                <p>{object.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* <button className="white-space-preline next-button" onClick={goTo}>
        <ArrowRight />
      </button> */}
    </div>
  );
};

export default SliderCarousel;
