import React from 'react';
import './KenyaMain.css';
import { Phone, Email, LocationOn, Language, Facebook, Instagram, LinkedIn, Twitter } from '@mui/icons-material';

function KenyaMain() {

  return (
    <div className="white-space-preline Apple">
      <header className="white-space-preline header">
        <h1>The Newberry Institute Expands to Nairobi, Kenya</h1>
        <div className="white-space-preline header-content">
          <p>
            We are thrilled to announce the opening of our new extension in Nairobi, Kenya.
            Located in the vibrant neighborhood of New Kitsuru, Nairobi, this expansion marks
            an exciting milestone for the institute as it continues to broaden its global reach.
          </p>
          <p>
            With this expansion, The Newberry Institute looks forward to collaborating with
            local institutions, conducting research projects, and empowering students and
            professionals alike. The Nairobi extension embodies our commitment to excellence,
            diversity, and positive impact.
          </p>
        </div>
        <div className="white-space-preline header-images">
          <img src="/assets/29.png" alt="New Campus" />
          <img src="/assets/30.png" alt="New Campus" />
        </div>
      </header>

      <div className="white-space-preline main-content">
        <section className="white-space-preline staff-spotlight">
          <h2>New Staff Spotlight</h2>
          <ul>
            <li>1: Elizabeth Kioko - Program Manager, Education</li>
            <li>2: Janice Fedha - Home Economics Manager</li>
            <li>3: Francis Delango - Gardener and Office Manager</li>
            <li>4: Festus Mumbai - Chauffeur & Outreach Coordinator</li>
            <li>5: Florence Salano - Chef & Domestic Services Officer</li>
          </ul>

          <p>We invite you to visit our new campus, explore its lush
            surroundings and engage with our passionate team.
            Together, we’ll continue to shape a brighter future
            through education, research, and community
            involvement.
          </p>
        </section>

        <section className="white-space-preline contact-us">
          <h2>Contact Us</h2>
          <ul>
            <li onClick={() => window.location.href = 'tel:+14782854685'}>
                <Phone style={{ marginRight: '10px' }} />
                <strong>Phone:</strong> +1 478-285-4685
            </li>
            <li onClick={() => window.location.href = 'https://goo.gl/maps/EXAMPLE'}>
                <LocationOn style={{ marginRight: '10px' }} />
                <strong>Address:</strong> 87 & 89 Commerce Street, Hawkinsville, GA
            </li>
            <li onClick={() => window.location.href = 'mailto:info@thenewberryfoundation.org'}>
                <Email style={{ marginRight: '10px' }} />
                <strong>Email:</strong> info@thenewberryfoundation.org
            </li>
            <li onClick={() => window.open('https://www.thenewberryinstitute.org', '_blank')}>
                <Language style={{ marginRight: '10px' }} />
                <strong>Website:</strong> www.thenewberryinstitute.org
            </li>

            <li>
              <div className='socialIcons hesen'>
                <Facebook className='icon' onClick={()=>{
                    window.open('https://www.facebook.com/newberryInstitute', '_blank');
                }} />
                <LinkedIn className='icon' onClick={()=>{
                    window.open('https://www.linkedin.com/company/thenewberryinstitute', '_blank');
                }} />
                <Twitter className='icon' onClick={()=>{
                    window.open('https://twitter.com/NewberryInst', '_blank');
                }} />
                <Instagram className='icon' onClick={()=>{
                    window.open('https://www.instagram.com/thenewberryinstitute', '_blank');
                }} />
                <span>@thenewberryinstitute</span>
              </div>
            </li>
         </ul>
        </section>
      </div>

      <footer className="white-space-preline footer">
        <p>
            Stay tuned for future issues that will offer critical insights, innovative solutions and bold visions for a more inclusive
            and equitable democracy. Together, let us explore the possibilities and potentials of democracy from an African
            American perspective and work towards a future where all voices are heard, all rights are respected and all
            communities are empowered to shape their own destinies. Thank you for joining us on this journey of discovery and
            enlightenment.
        </p>
      </footer>
    </div>
  );
}

export default KenyaMain;
