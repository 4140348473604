import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';  // Import useLocation
import DashboardTopBar from '../../Layouts/DashboardTopBar';
import BaseUrl from '../../../BaseUrl';
import './Email.css'
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../../axiosInstance';

function EditEmail({ handleToggle, togActive }) {

  const Navigate = useNavigate()
  
  const [formData, setFormData] = useState({
    email: '',
  });

  const location = useLocation();  // Get the location object

  useEffect(() => {
    const id = location.pathname.split('/').pop();
    axios.get(`${BaseUrl}/api/get-single-email/${id}`)
      .then(response => {
        setFormData(response.data.email);
      })
      .catch(error => {
        console.error('Error fetching email:', error);
      });
  }, [location]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const id = location.pathname.split('/').pop();
    axiosInstance.put(`${BaseUrl}/api/update-single-email/${id}`, formData)
      .then(response => {
        Navigate('/dashboard/emails');
      })
      .catch(error => {
        console.error('Error updating email:', error);
      });
  };

  return (
    <div className="white-space-preline container">
      <div className={`main ${togActive && "active"}`}>
        <DashboardTopBar handleToggle={handleToggle} />

        <div className='emailForm'>
          <form onSubmit={handleSubmit}>
           <h1>Edit email</h1>
            <div>
              <label>Email:</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>

            <button type="submit">Update email</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditEmail;
