import React, { useState, useEffect } from 'react';
import DashboardTopBar from '../../Layouts/DashboardTopBar';
import { Link, useNavigate } from 'react-router-dom';
import { Edit, RemoveRedEyeOutlined } from '@mui/icons-material';
import axiosInstance from '../../../axiosInstance';

function IndexDonation({ handleToggle, togActive }) {
    const Navigate = useNavigate()
  const [donations, setDonations] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch data from the API
    axiosInstance
      .get(`/api/get-all-donation`)
      .then(response => {
        setDonations(response.data.donations); // Assuming your API returns an array of donation objects
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching donations:', error);
        setLoading(false);
      });
  }, []);
  

  const handleDelete = (donationId) => {
    // Send a DELETE request to the API to delete the donation
    axiosInstance
      .delete(`/api/delete-single-donation/${donationId}`)
      .then(response => {
        // Remove the deleted donation from the state
        setDonations(prevDonations => prevDonations.filter(donation => donation.id !== donationId));
        console.log('Donation deleted successfully:', response.data);
      })
      .catch(error => {
        console.error('Error deleting donation:', error);
      });
  };


  return (
    <div className="white-space-preline container">
      <div className={`main ${togActive && "active"}`}>
        <DashboardTopBar handleToggle={handleToggle} />

        <div className="white-space-preline cardBox">
          {/* ... */}
        </div>

        <div className="white-space-preline details">
          <div className="white-space-preline recentOrders">
            <div className="white-space-preline cardHeader">
              <h2>Recent donations</h2>

              <Link to="/dashboard/create-donation">
                Create donation
              </Link>
            </div>

            <table>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="2">Loading...</td>
                  </tr>
                ) : (
                  donations.map(donation => (
                    <tr key={donation.id}>
                      <td>{donation.email}</td>
                      <td>{donation.amount}</td>
                      <td><RemoveRedEyeOutlined onClick={()=>{
                        Navigate(`/dashboard/show-donation/${donation.id}`)
                      }} /> </td>
                      <td><Edit onClick={()=>{
                        Navigate(`/dashboard/edit-donation/${donation.id}`)
                      }} /> </td>
                      <td>
                        <button onClick={() => handleDelete(donation.id)}>
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IndexDonation;
