import React, { useState, useEffect } from 'react';
import DashboardTopBar from '../../Layouts/DashboardTopBar';
import { Link } from 'react-router-dom';
import axiosInstance from '../../../axiosInstance';

function IndexEvent({ handleToggle, togActive }) {
  const [eventList, setEventList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch event records from the API using custom Axios instance
    axiosInstance
      .get('/api/get-all-event')
      .then((response) => {
        setEventList(response.data.events); // Assuming your API returns an array of event records
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching event records:', error);
        setLoading(false);
      });
  }, []);



  const handleDelete = (eventId) => {
    // Send a DELETE request to the API to delete the event record using custom Axios instance
    axiosInstance
      .delete(`/api/delete-single-event/${eventId}`)
      .then(() => {
        // Remove the deleted event record from the state
        setEventList((prevEventList) =>
          prevEventList.filter((event) => event.id !== eventId)
        );
        console.log('event record deleted successfully.');
      })
      .catch((error) => {
        console.error('Error deleting event record:', error);
      });
  };

  

  return (
    <div className="white-space-preline container">
      <div className={`main ${togActive && 'active'}`}>
        <DashboardTopBar handleToggle={handleToggle} />

        <div className="white-space-preline details">
          <div className="white-space-preline recentOrders">
            <div className="white-space-preline cardHeader">
              <h2>Events</h2>
              <Link to="/dashboard/create-event">Create event</Link>
            </div>

            <table>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="5">Loading...</td>
                  </tr>
                ) : (
                  eventList.map((event) => (
                    <tr key={event.id}>
                      <td>{event.title}</td>
                      <td>{event.flag}</td>
                      <td>
                        <Link to={`/dashboard/show-event/${event.id}`}>
                          View
                        </Link>
                      </td>
                      <td>
                        <Link to={`/dashboard/edit-event/${event.id}`}>
                          Edit
                        </Link>
                      </td>
                      <td>
                        <button onClick={() => handleDelete(event.id)}>
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IndexEvent;
