import React from 'react'
import Customer1 from '../images/log.png'

function DashboardTopBar({handleToggle}) {
  return (
    <div className="white-space-preline topbar">
        <div className="white-space-preline toggle" onClick={handleToggle}>
            <ion-icon name="menu-outline"></ion-icon>
        </div>


        <div className="white-space-preline user">
            <img src={Customer1} alt="" />
        </div>
    </div>
  )
}

export default DashboardTopBar
