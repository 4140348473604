import React, { useState } from 'react';
import DashboardTopBar from '../../Layouts/DashboardTopBar';
import BaseUrl from '../../../BaseUrl';
import './User.css'
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../../axiosInstance';

function Create({ handleToggle, togActive }) {

  const Navigate = useNavigate()

  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    formDataToSend.append('username', formData.username);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('password', formData.password);

    // Send the form data using Axios from axiosInstance
    axiosInstance
      .post(`${BaseUrl}/api/add-user`, formDataToSend)
      .then((response) => {
        // Handle success, e.g., redirect to a success page or show a success message
        Navigate('/dashboard/users');
      })
      .catch((error) => {
        // Handle error, e.g., show an error message to the user
        console.error('Error creating user:', error);
      });
  };

  return (
    <div className="white-space-preline container">
      <div className={`main ${togActive && 'active'}`}>
        <DashboardTopBar handleToggle={handleToggle} />

        <div className='createForm'>
          <form onSubmit={handleSubmit}>
          <h1>Create user</h1>
            <div>
              <label>Username:</label>
              <input
                type="text"
                name="username"
                value={formData.username}
                onChange={handleChange}
              />
            </div>

            <div>
              <label>Email:</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>


            <div>
              <label>Password:</label>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
              />
            </div>
            <button type="submit">Create user</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Create;
