import React, { useState, useEffect } from 'react';
import DashboardTopBar from '../../Layouts/DashboardTopBar';
import { Link } from 'react-router-dom';
import axiosInstance from '../../../axiosInstance';

function IndexActionNetwork({ handleToggle, togActive }) {
  const [actionNetworkList, setActionNetwork] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch actionNetwork records from the API using custom Axios instance
    axiosInstance
      .get('/api/get-all-action-network')
      .then((response) => {
        setActionNetwork(response.data.actionNetworks); // Assuming your API returns an array of actionNetwork records
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching actionNetwork records:', error);
        setLoading(false);
      });
  }, []);



  const handleDelete = (actionNetworkId) => {
    // Send a DELETE request to the API to delete the actionNetwork record using custom Axios instance
    axiosInstance
      .delete(`/api/delete-single-action-network/${actionNetworkId}`)
      .then(() => {
        // Remove the deleted actionNetwork record from the state
        setActionNetwork((prevActionNetwork) =>
          prevActionNetwork.filter((actionNetwork) => actionNetwork.id !== actionNetworkId)
        );
        console.log('actionNetwork record deleted successfully.');
      })
      .catch((error) => {
        console.error('Error deleting actionNetwork record:', error);
      });
  };

  

  return (
    <div className="white-space-preline container">
      <div className={`main ${togActive && 'active'}`}>
        <DashboardTopBar handleToggle={handleToggle} />

        <div className="white-space-preline details">
          <div className="white-space-preline recentOrders">
            <div className="white-space-preline cardHeader">
              <h2>Action networks</h2>
              <Link to="/dashboard/create-action-network">Create action network</Link>
            </div>

            <table>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="5">Loading...</td>
                  </tr>
                ) : (
                  actionNetworkList.map((actionNetwork) => (
                    <tr key={actionNetwork.id}>
                      <td>{actionNetwork.title}</td>
                      <td>{actionNetwork.link}</td>
                      <td>
                        <Link to={`/dashboard/show-action-network/${actionNetwork.id}`}>
                          View
                        </Link>
                      </td>
                      <td>
                        <Link to={`/dashboard/edit-action-network/${actionNetwork.id}`}>
                          Edit
                        </Link>
                      </td>
                      <td>
                        <button onClick={() => handleDelete(actionNetwork.id)}>
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IndexActionNetwork;
