import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DashboardTopBar from '../../Layouts/DashboardTopBar';
import axiosInstance from '../../../axiosInstance';

function EditTeam({ handleToggle, togActive }) {
  const Navigate = useNavigate();

  const location = useLocation();
  const teamId = location.pathname.split('/').pop();

  const [formData, setFormData] = useState({
    image: null,
    name: '',
    type: '',
    position: '',
    workstation: '',
  });

  useEffect(() => {
    axiosInstance
      .get(`/api/get-single-team/${teamId}`)
      .then((response) => {
        const teamData = response.data.team;
        setFormData(teamData);
      })
      .catch((error) => {
        console.error('Error fetching team data:', error);
      });
  }, [teamId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    setFormData({ ...formData, image: e.target.files[0] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    formDataToSend.append('image', formData.image);
    formDataToSend.append('name', formData.name);
    formDataToSend.append('type', formData.type);
    formDataToSend.append('position', formData.position);
    formDataToSend.append('workstation', formData.workstation);
  
    // Send the form data using Axios
    axiosInstance
      .post(`/api/update-single-team/${teamId}`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set the correct content type for FormData
        },
      })
      .then((response) => {
        Navigate('/dashboard/teams');
      })
      .catch((error) => {
        console.error('Error updating team:', error);
      });
  };
  

  return (
    <div className="white-space-preline container">
      <div className={`main ${togActive && 'active'}`}>
        <DashboardTopBar handleToggle={handleToggle} />

        <div className="white-space-preline teamForm">
          <form onSubmit={handleSubmit}>
            <h1>Edit Team</h1>
            <div>
              <label>Image:</label>
              <input
                type="file"
                accept="image/*"
                name="image"
                onChange={handleImageChange}
              />
            </div>

            <div>
              <label>Name:</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </div>

            <div>
              <label>Position:</label>
              <input
                type="text"
                name="position"
                value={formData.position}
                onChange={handleChange}
              />
            </div>

            <div>
              <label>Workstation:</label>
              <input
                type="text"
                name="workstation"
                value={formData.workstation}
                onChange={handleChange}
              />
            </div>

            <div>
              <label>Team Type:</label>
              <select
                name="type"
                value={formData.type}
                onChange={handleChange}
              >
                <option value="">Select Team Type</option>
                <option value="director">Director</option>
                <option value="staff">Staff</option>
                <option value="advisory">Advisory</option>
              </select>
            </div>

            <button type="submit">Update Team</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditTeam;
