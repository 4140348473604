import React from 'react'
import DashboardTopBar from '../../Layouts/DashboardTopBar';
import { Link } from 'react-router-dom';
function IndexStory({handleToggle, togActive}) {
  const rowStyle = {
    display: 'flex',
    justifyContent: 'space-between',
  };
  return (
    <div className="white-space-preline container">
        

        <div className={`main ${togActive && "active"}`}>
            <DashboardTopBar handleToggle={handleToggle} />

            <div className="white-space-preline cardBox">

                <div className="white-space-preline card">
                    <div>
                        <div className="white-space-preline numbers">$7,842</div>
                        <div className="white-space-preline cardName">Donations</div>
                    </div>

                    <div className="white-space-preline iconBx">
                        <ion-icon name="cash-outline"></ion-icon>
                    </div>
                </div>

                <div className="white-space-preline card">
                    <div>
                        <div className="white-space-preline numbers">The Newberry Institute</div>
                    </div>
                </div>
            </div>

            <div className="white-space-preline details">
                <div className="white-space-preline recentOrders">
                    <div className="white-space-preline cardHeader">
                        <h2>Stories</h2>
                        <Link to="/dashboard/create-story">
                          View All
                        </Link>
                    </div>

                    <table>
                        <thead>
                            <tr style={rowStyle}>
                                <th>Email</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Star Refrigerator</td>
                                <td>$1200</td>
                            </tr>
                            <tr>
                                <td>Dell Laptop</td>
                                <td>$110</td>
                            </tr>
                            <tr>
                                <td>Apple Watch</td>
                                <td>$1200</td>
                            </tr>
                            <tr>
                                <td>Addidas Shoes</td>
                                <td>$620</td>
                            </tr>
                            <tr>
                                <td>Star Refrigerator</td>
                                <td>$1200</td>
                            </tr>
                            <tr>
                                <td>Dell Laptop</td>
                                <td>$110</td>
                            </tr>
                            <tr>
                                <td>Apple Watch</td>
                                <td>$1200</td>
                            </tr>
                            <tr>
                                <td>Addidas Shoes</td>
                                <td>$620</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
  )
}

export default IndexStory
