import React, { useState, useEffect } from 'react';
import DashboardTopBar from '../../Layouts/DashboardTopBar';
import { Link } from 'react-router-dom';
import axiosInstance from '../../../axiosInstance';

function IndexTeam({ handleToggle, togActive }) {
  const [teamList, setTeamList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch team records from the API using custom Axios instance
    axiosInstance
      .get('/api/get-all-team')
      .then((response) => {
        setTeamList(response.data.teams); // Assuming your API returns an array of team records
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching team records:', error);
        setLoading(false);
      });
  }, []);

  



  const handleDelete = (teamId) => {
    // Send a DELETE request to the API to delete the team record using custom Axios instance
    axiosInstance
      .delete(`/api/delete-single-team/${teamId}`)
      .then(() => {
        // Remove the deleted team record from the state
        setTeamList((prevTeamList) =>
          prevTeamList.filter((team) => team.id !== teamId)
        );
        console.log('team record deleted successfully.');
      })
      .catch((error) => {
        console.error('Error deleting team record:', error);
      });
  };

  

  return (
    <div className="white-space-preline container">
      <div className={`main ${togActive && 'active'}`}>
        <DashboardTopBar handleToggle={handleToggle} />

        <div className="white-space-preline details">
          <div className="white-space-preline recentOrders">
            <div className="white-space-preline cardHeader">
              <h2>Team</h2>
              <Link to="/dashboard/create-team">Create team</Link>
            </div>

            <table>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="5">Loading...</td>
                  </tr>
                ) : (
                    teamList && teamList.map((team) => (
                    <tr key={team.id}>
                      <td>{team.name}</td>
                      <td>{team.position}</td>
                      <td>{team.workstation}</td>
                      <td>
                        <Link to={`/dashboard/show-team/${team.id}`}>
                          View
                        </Link>
                      </td>
                      <td>
                        <Link to={`/dashboard/edit-team/${team.id}`}>
                          Edit
                        </Link>
                      </td>
                      <td>
                        <button onClick={() => handleDelete(team.id)}>
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IndexTeam;
