import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import DashboardTopBar from '../../Layouts/DashboardTopBar';
import BaseUrl from '../../../BaseUrl';
import './Email.css'
import axiosInstance from '../../../axiosInstance';

function ShowEmail({ handleToggle, togActive }) {
  const [email, setEmail] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const id = location.pathname.split('/').pop();
    axiosInstance.get(`${BaseUrl}/api/get-single-email/${id}`)
      .then(response => {
        setEmail(response.data.email);
      })
      .catch(error => {
        console.error('Error fetching email:', error);
      });
  }, [location]);

  return (
    <div className="white-space-preline container">
      <div className={`main ${togActive && "active"}`}>
        <DashboardTopBar handleToggle={handleToggle} />

        <div className='emailShowDash'>
          
          {email ? (
            <div>
              <h1>Email Details</h1>
              <p>Email: {email.email}</p>
              {/* Add more email details here */}
            </div>
          ) : (
            <p>Loading email details...</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default ShowEmail;
