import React from 'react'
import DashboardTopBar from '../../Layouts/DashboardTopBar';
function ShowStory({handleToggle, togActive}) {
  return (
    <div className="white-space-preline container">
        

        <div className={`main ${togActive && "active"}`}>
            <DashboardTopBar handleToggle={handleToggle} />

            <div>This is add page</div>
        </div>
    </div>
  )
}

export default ShowStory
