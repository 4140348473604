import React, { useState } from 'react';
import DashboardTopBar from '../../Layouts/DashboardTopBar';
import './Email.css'
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../../axiosInstance';

function CreateEmail({ handleToggle, togActive }) {

  const Navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    // Send a POST request to create a email
    axiosInstance
      .post(`/api/add-email`, formData)
      .then(response => {
        Navigate('/dashboard/emails');
        // Handle success (e.g., show a success message)
      })
      .catch(error => {
        console.error('Error creating email:', error);
        // Handle error (e.g., show an error message)
      });
  };
  

  return (
    <div className="white-space-preline container">
      <div className={`main ${togActive && "active"}`}>
        <DashboardTopBar handleToggle={handleToggle} />

        <div className='emailForm'>
          <h1>Create email</h1>
          <form onSubmit={handleSubmit}>
            <div>
              <label>Email:</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateEmail;
